.main {
    min-height: 100vh;
}

.logo-text {
    display: block;
    padding-top: 5vh;
    padding-left: 5vw;
    width: 15vw;
}

.large-logo {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 0;
    width: 25vw;
}

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 auto;
    padding-top: 8vh;
    width: 60vw;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.explain-welcome {
    font-size: 1.1rem;
}

.title {
    font-family: "Sequel Medium";
    font-size: 5rem;
    line-height: 5.5rem;
}

p {
    font-family: "Sequel";
    color: #CEBEFA;
}

.alert {
    position: fixed;
    bottom: 2vh;
    margin: auto;
    left:0;
    right:0;
    width: 30vw;
    z-index: 999;
}

@media (max-width: 632px)
{
    .container {
        width: 90vw;
    }

    .alert {
        width: 90vw;
    }

    .large-logo {
        width: 50vw;
    }

    .logo-text {
        width: 20vh;
    }

    .title {
        font-size: 2.5rem;
        line-height: 2.75rem;
    }

    .explain-welcome {
        font-size: 0.8rem;
    }
}