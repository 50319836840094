.App {
  text-align: center;
  margin: 0;
}

@font-face {
  font-family: "Sequel";   /*Can be any text*/
  src: local("Sequel_Sans_Roman_Disp"),
  url("./fonts/Sequel_Sans_Roman_Disp.otf") format("OpenType");
}

@font-face {
  font-family: "Sequel Light";   /*Can be any text*/
  src: local("Sequel_Sans_Light_Disp"),
  url("./fonts/Sequel_Sans_Light_Disp.otf") format("OpenType");
}

@font-face {
  font-family: "Sequel Medium";   /*Can be any text*/
  src: local("Sequel_Sans_Medium_Disp"),
  url("./fonts/Sequel_Sans_Medium_Disp.otf") format("OpenType");
}

@font-face {
  font-family: "Sequel Bold";   /*Can be any text*/
  src: local("Sequel_Sans_Semi_Bold_Head"),
  url("./fonts/Sequel_Sans_Semi_Bold_Head.otf") format("OpenType");
}

@font-face {
  font-family: "Sequel Medium Oblique";   /*Can be any text*/
  src: local("Sequel_Sans_Medium_Obl_Disp"),
  url("./fonts/Sequel_Sans_Medium_Obl_Disp.otf") format("OpenType");
}