.info-container {
    min-height: 100vh;
    margin: 0;
}

.arrow-back {
    display: block;
    object-fit: contain;
}

.arrow-back:hover {
    cursor: pointer;
}

.logo-text-info {
    display: block;
    object-fit: contain;
}

.container-info {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 0;
    margin: 0 auto;
    width: 50vw;
    align-items: center;
    justify-content: center;
}

.info-title {
    font-family: "Sequel Medium";
    font-size: 3rem
}

.next-button:active {
    opacity: 0.75;
}

.next-button {
    background-color: #734DC2;
    width: 25%;
}

.arrow-next {
    display: block;
    object-fit: contain;
}

.completion-container {
    left:0;
    right:0;

    margin-left: auto;
    margin-right: auto;

    position: fixed;
    bottom: 5vh;
    width: 20%;
}

.shadow-completion {
    -webkit-box-shadow: 0px 0px 3px 1px #734DC2;
    box-shadow: 0px 0px 3px 1px #734DC2;
}

.question {
    font-size: 2rem;
}

@media (max-width: 632px)
{
    .header-info {
        padding-bottom: 3vh;
    }

    .container-info {
        width: 80vw;
        padding-bottom: 15vh;
    }

    .info-title {
        font-size: 2.5rem
    }

    .next-button {
        width: 50%;
    }

    .completion-container {
        width: 50%;
    }

    .question {
        font-size: 1.5rem;
    }
}
