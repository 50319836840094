.main-end {
    min-height: 100vh;
}

.container-end {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 auto;
    padding-top: 0vh;
    width: 60vw;
    align-items: center;
    justify-content: center;
}

.title {
    font-family: "Sequel Medium";
    font-size: 5rem;
}

.end-text {
    font-family: "Sequel";
    color: #CEBEFA;
    font-size: 1.25rem;
}

.end-text-keez {
    font-family: "Sequel Bold";
    color: #CEBEFA;
    background-color: transparent;
    font-size: 1.5rem;
}

.shadow-text {
    text-shadow: #FFFFFF 0px 0 2px;
}

@media (max-width: 632px)
{
    .container-end {
        padding-top: 5vh;
        width: 80vw;
        z-index: 999;
    }

    .title {
        font-size: 2.5rem;
    }

    .end-text {
        font-size: 0.9rem;
    }

    .end-text-keez {
        font-size: 1.15rem;
    }
}