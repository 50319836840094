.item:hover {
    cursor: pointer;
}

.item {
    font-size: 1.5rem;
}

@media (max-width: 632px)
{
    .item {
        font-size: 1.25rem;
    }
}