.speaker-container {
    height: 100vh;
    display: flex;
    width: 60vw;
    margin: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ai-text {
    font-size: 1.5rem;
}

@media (max-width: 632px)
{
    .ai-text {
        font-size: 1.25rem;
    }
}