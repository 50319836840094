.button:active {
    opacity: 0.75;
}

.button {
    background-color: #E8DDFC;
    padding-left: 100px;
    padding-right: 100px;
}

.loader {
    width: 25px;
    height: 25px;
    border: 3px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.shadow-button {
    -webkit-box-shadow: 0px 0px 15px 1px #FFFFFF;
    box-shadow: 0px 0px 15px 1px #FFFFFF;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 632px)
{
    .button {
        padding-left: 12vw;
        padding-right: 12vw;
    }
}